<template>
    <div>   
        <div>
            <v-dialog 
                v-model="show_dialog"
                scrollable   
                :persistent="fixed_dialog" :overlay="false"
                :max-width="DialogWidth"
                transition="dialog-transition" >
                <v-card rounded="xl" class="b1" > 
                    <v-toolbar color="primary" dark h>
                        <v-icon class="mr-2">{{'search'}}</v-icon>
                        <v-toolbar-title>{{ title?title:"Find Receipt / Invoice" }}</v-toolbar-title>
                        <v-spacer></v-spacer>
                        <v-btn icon @click="show_dialog = false"><v-icon>close</v-icon></v-btn>
                    </v-toolbar>
                    <v-card :height="DialogHeight" flat color="transparent" class="my-4"> 
                        <v-card-text>
                            <v-autocomplete class="mx-3 my-2"
                                v-model="input_receipt"
                                :items="Receipts"
                                :loading="isLoading"
                                :search-input.sync="search"
                                color="primary"
                                hide-no-data
                                hide-selected
                                item-text="order_id"
                                item-value="key"
                                label="Search Receipt"
                                placeholder="Start typing to Search"
                                prepend-icon="mdi-database-search"
                                return-object
                                clearable
                                :error="receipts_error"
                                :error-messages="receipts_error_message" 
                            ></v-autocomplete>

                            <v-alert v-model="receipts_error"
                                 class="mx-3"
                                :dismissible="true"
                                color="error"
                                border="left"
                                elevation="2"
                                colored-border
                                icon="mdi-file-document-alert-outline" 
                                >
                                 We found no record with " <strong>No. {{ search }}</strong> ". Please check and try again.
                                </v-alert>
                            
                        </v-card-text> 
                    </v-card>
                </v-card> 
            </v-dialog>   
        </div>
    </div> 
</template> 
<script> 

    import DATA from "../../plugins/DATA";
    import {mapState} from "vuex" 
    import DB from "../../plugins/DB"; 
    import firebase from "firebase/app";
    import { toNumber } from "../../plugins/formatNumber";
    import { mdiTextBoxSearch } from '@mdi/js';
    const db = firebase.database();
    const PAGE_NAME = "SHOW_INVOICE"

    export default {
        props:[
            'show','fixed','title','width','height','receipt_key',],
        data(){
            return{ 
                show_dialog:false,
                fixed_dialog:false,
                dialog_show_doc:this.value,   
                auto_print:{
                    auto:false,
                    printing:false, 
                    print_data:null,
                    print_type:'sale_order',
                    print_title:'INVOICE',
                    com_printer:false, 
                    printer_name:null,
                    printing_action:null, 
                    display_option:'', 
                },  
                descriptionLimit: 60,
                entries: [],
                isLoading: false,
                search: null, 
                input_receipt: null,
                searched_receipts: null, 
                return_data: null, 
                mdiTextBoxSearch: mdiTextBoxSearch, 
                receipts_error:false,
                receipts_error_message:null
            }
        },
        created(){ 
            try {
                this.MBS.events.$on('DIALOG_YES', this.DIALOG_YES);  
                this.MBS.events.$on('ON_FIND_RECEIPTS', this.ON_FIND_RECEIPTS);  
            } catch (error) {
                this.MBS.actions.error({
                    error:error,
                    from:'created',
                    page:PAGE_NAME, 
                }) 
            }
        },
        mounted(){  
            if (this.show) {
                this.show_dialog = true
            }else{
                this.show_dialog = false
            }
            
            if (this.fixed) {
                this.fixed_dialog = true
            }else{
                this.fixed_dialog = false
            }
             
        },
        computed:{
            vs(){
                let vs = this.$vuetify.breakpoint 
                return vs
            }, 
            vs_width(){
                let vs = this.vs
                if(!vs){return null}
                return vs.width
            },
            vsh(){
                return this.vs.height
            },
            DialogWidth(){ 
                let width = this.width   
                return width?width:'600'
            },
            DialogHeight(){ 
                let height = this.height   
                return height?height:''
            },
            ...mapState({
                loading:state=>state.load.loading,
                processes:state=>state.load.processes,
                responses:state=>state.load.responses,
            }),
            ...mapState({
                us: state=> state.users[(DATA.ITEMS.USERS.value).toUpperCase()],
                ud: state=> state.users[(DATA.ITEMS.USER_DETAILS.values).toUpperCase()],
                SELECTED_COMPANY: state=> state.items['SELECTED_COMPANY'],
                K_CONNECTOR: state=> state.items['K_CONNECTOR'],
            }),
            ...mapState({
                CompanySettings: state=> state.items[(DATA.ITEMS.COMPANY_SETTINGS.values).toUpperCase()], 
                CompanyQuickActions: state=> state.items[(DATA.ITEMS.COMPANY_QUICK_ACTIONS.values).toUpperCase()], 
                 
                SaleOrders: state=> state.items[(DATA.ITEMS.SALE_ORDERS.values).toUpperCase()],
                JoinedSaleOrders: state=> state.join[(DATA.ITEMS.SALE_ORDERS.values).toUpperCase()],  
                LocalSaleOrders: state=> state.items[(DATA.ITEMS.LOCAL_SALE_ORDERS.values).toUpperCase()],
                JoinedLocalSaleOrders: state=> state.join[(DATA.ITEMS.LOCAL_SALE_ORDERS.values).toUpperCase()],
            }), 
            DataSaleOrders(){
                let SaleOrders = this.SaleOrders
                let JoinedSaleOrders = this.JoinedSaleOrders
                return JoinedSaleOrders?JoinedSaleOrders:SaleOrders
            },
            AllLocalSaleOrdersData(){  
                const LocalSaleOrders = this.LocalSaleOrders
                const JoinedLocalSaleOrders = this.JoinedLocalSaleOrders
                let items = JoinedLocalSaleOrders?JoinedLocalSaleOrders:LocalSaleOrders  
                if(!items){return null} 
                let list = [...items]
                list.sort(this.MBS.actions.dynamicSort("local_created_at",true)) 
                return list
            }, 
            BothSaleOrders(){
                let orders = this.DataSaleOrders
                let localOrders = this.AllLocalSaleOrdersData
                console.log(orders,'list....');
                console.log(localOrders,'list....');
                if(!orders && !localOrders){return null}
                if(orders && !localOrders){return orders}
                if(!orders && localOrders){return localOrders}

                let both = [...orders]
                localOrders.forEach(element => {
                    let item = orders.find(item=>{
                        return item.key == element.key
                    })
                    if (!item) {
                        both.push(element)
                    } 
                });
                let list = [...both]
                list.sort(this.MBS.actions.dynamicSort("local_created_at",false)) 
                
                return list
            },
            ThisSaleOrder(){ 
                let orders = this.BothSaleOrders
                let data_key = this.data_key
                let data = this.data
                console.log(data_key,'key..');
                if (data) {
                    return data
                }
                if(!orders || !data_key){return null}
                let items = orders.find(order=>{
                    return order.key == data_key
                })
                return items
            },  
            ReceiptNumber(){  
                let data_key = this.data_key
                let data = this.data 
                let order = this.ThisSaleOrder 
                let receipt_number_ = order?order.receipt_number_:null
                if (receipt_number_) {
                    return receipt_number_
                }
                if (data) {
                    return data.key
                } 
                return data_key
            },  
            InvoiceSettings(){
                let settings = this.CompanySettings 
                let template_settings = settings?settings.template_settings:null 
                let invoice = template_settings?template_settings.invoice:null 
                return invoice
            }, 
            ReceiptSettings(){
                let settings = this.CompanySettings 
                let template_settings = settings?settings.template_settings:null 
                let receipt = template_settings?template_settings.receipt:null 
                return receipt
            }, 
            quotationSettings(){
                let settings = this.CompanySettings 
                let template_settings = settings?settings.template_settings:null 
                let quotation = template_settings?template_settings.quotation:null 
                return quotation
            }, 
            TempSettings(){
                let InvoiceSettings = this.InvoiceSettings
                let ReceiptSettings = this.ReceiptSettings
                let quotationSettings = this.quotationSettings
                let document_type = this.document_type
                let temp_settings = null
                if (this.MBS.actions.TEXT_UP(document_type) == "RECEIPT") {
                    temp_settings = ReceiptSettings 
                } else if (this.MBS.actions.TEXT_UP(document_type) == "INVOICE") {
                    temp_settings = InvoiceSettings 
                }else if (this.MBS.actions.TEXT_UP(document_type) == "QUOTATION") {
                    temp_settings = ReceiptSettings 
                } else {
                    temp_settings = quotationSettings 
                } ;
                return temp_settings
            },
            Receipts(){ 
                const searched_receipts = this.searched_receipts
                return searched_receipts
            }, 
              
        },
        methods:{ 
            TABLE_ACTION(action,item){
                try { 
                    if (action == "order_stock") {
                        let link = this.MBS.actions.COMPANY_LINK("/stock/stock-control/sales-return",true)
                        this.MBS.actions.go(link)
                    } 
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'TABLE_ACTION',
                        page:PAGE_NAME, 
                    }) 
                }
                
            },
            ITEM_ACTION(action,item){
                try { 
                    let link = "/stock/stock-control/"+(item?item.key:null)
                    const path = this.MBS.actions.COMPANY_LINK(link,true)  
                    if (action == "view_order") {
                        let receipt_key = item?item.key:null
                        this.MBS.actions.go("/sales-receipt/"+receipt_key)
                    } 
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'TABLE_ACTION',
                        page:PAGE_NAME, 
                    }) 
                }
                
            },
            FINISH_PRINT(action,payload){
                try { 
                    let canceled = payload?payload.canceled:null
                    if (canceled) {
                        
                    }else{
                        setTimeout(() => {
                                // this.MBS.actions.go(-1)   
                        }, 1); 
                    }
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'FINISH_PRINT',
                        page:PAGE_NAME, 
                    }) 
                } 
            }, 
            ON_PDF(){
                try { 
                     this.MBS.actions.dialog(null,true,false,
                        "PDF OPTION",
                        "Current not working. Try again later. For more info contact system admin",null,"OK") 
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'ON_PDF',
                        page:PAGE_NAME, 
                    }) 
                }
                
            },
            ON_EXCEL(){
                try { 
                    this.MBS.actions.dialog(null,true,false,
                        "EXCEL OPTION",
                        "Current not working. Try again later. For more info contact system admin",null,"OK")  
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'ON_EXCEL',
                        page:PAGE_NAME, 
                    }) 
                }
                
            },
            ON_EMAIL(){
                try { 
                    this.MBS.actions.dialog(null,true,false,
                        "EMAIL OPTION",
                        "Current not working. Try again later. For more info contact system admin",null,"OK") 
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'ON_EXCEL',
                        page:PAGE_NAME, 
                    }) 
                }
                
            },
            ON_PRINTING(){
                try { 
                    let lastSale = this.ThisSaleOrder 
                    let printer = this.CurrentReceiptPrinter
                    let printer_name = printer?printer.name:null
                    let printer_type_name = printer?printer.printer_type_name:null 
                    this.auto_print.printing = false
                    this.auto_print.display_option = this.InvoiceSettings?this.InvoiceSettings.display_options=='pdf_view'?'pdf':'':''
                    setTimeout(() => {
                        if (printer_type_name) {
                            // this.auto_print.auto = true
                        }
                        this.auto_print.printer_name = printer_name
                        this.auto_print.print_data = lastSale 
                        this.auto_print.printing = true   
                    }, 5); 
                     
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'ON_EXCEL',
                        page:PAGE_NAME, 
                    }) 
                }
                
            }, 
            ON_CLOSE(data){
                try { 
                    this.dialog_show_doc = false 
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'ON_CLOSE',
                        page:PAGE_NAME, 
                    }) 
                }
                
            },  
            ON_FIND_RECEIPTS(data){
                try { 
                    const show = data?.show
                    const fixed = data?.fixed
                    const receipt_key = data?.receipt_key
                    if (receipt_key) {
                        this.SEARCH_RECEIPTS(receipt_key)
                    }  
                    if(show){
                        this.show_dialog = true  
                    }if(fixed){
                        this.fixed_dialog = true  
                    }
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'ON_FIND_RECEIPTS',
                        page:PAGE_NAME, 
                    }) 
                } 
            }, 
            async SEARCH_RECEIPTS(key_word){
                try { 
                    this.receipts_error_message=null
                    this.receipts_error=false
                    this.isLoading = true
                    const receipt_path = this.MBS.actions.COMPANY_LINK(DATA.PATHS.days_shifts_sales_receipts,true) 
                    const receipt_ref = db.ref(receipt_path) 
                    const query = receipt_ref.orderByChild('order_id').startAt(key_word).endAt(`${key_word}\uf8ff`).limitToLast(10)
                    const receiptSnapshot = await query.once('value'); 
                    const receipts_obj = receiptSnapshot.val()
                    const receipts = receipts_obj?Object.entries(receipts_obj)
                    .filter(([key, value]) => value !== null && value !== undefined)
                    .map(([key,value])=>({
                        ...value,
                        key:key
                    })):null  
                    this.searched_receipts = receipts 
                    this.isLoading = false 
                    if (this.MBS.actions.SIZE(receipts) ==0 ) {
                        this.receipts_error_message="Code Not found..."
                        this.receipts_error = true
                    }
                    if (this.MBS.actions.SIZE(receipts) ==1 ) {
                        this.SEARCH_SALES_ORDER(receipts[0])
                    }else{
                        let return_data = this.return_data?this.return_data:{} 
                        return_data = {
                            ...return_data,
                            receipt:this.input_receipt,
                            searching_key:this.search,
                            searched_receipts:this.searched_receipts
                        }
                        this.return_data = return_data
                        this.$emit("input",return_data)   
                    } 
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'SEARCH_RECEIPTS',
                        page:PAGE_NAME, 
                    }) 
                }
                
            }, 
            async SEARCH_SALES_ORDER(receipt){
                try { 
                    this.isLoading = true
                    const receipt_key = receipt?.key
                    const day_shift_key = receipt?.day_shift_key
                    const dayshifts_path = this.MBS.actions.COMPANY_LINK(DATA.PATHS.days_shifts_sales,true) 
                    const sales_return_path = this.MBS.actions.COMPANY_LINK(DATA.PATHS.sales_return,true) 
                    const dayshifts_ref = db.ref(dayshifts_path) 
                    const sales_return_ref = db.ref(sales_return_path) 
                    const query = dayshifts_ref.child(day_shift_key+"/"+receipt_key)
                    const saleSnapshot = await query.once('value'); 
                    const sale_order = saleSnapshot.val() 
                    let sales_returns = null
                    if (sale_order.sales_return) {
                        const return_query = sales_return_ref.orderByChild('order_id')
                        const returnsSnapshot = await return_query.once('value'); 
                        const returns_obj = returnsSnapshot.val()
                        sales_returns = returns_obj?Object.entries(returns_obj)
                        .filter(([key, value]) => value !== null && value !== undefined)
                        .map(([key,value])=>({
                            ...value,
                            key:key
                        })):null  
                    }
                    
                    const res_join = await this.$store.dispatch('join_sale_orders',{data:sale_order})
                    const join_sale_order = res_join?.data
                    
                    const res_join_returns = await this.$store.dispatch('join',{data:sales_returns,name:"ALL_"+DATA.ITEMS.SALE_ORDERS.values})
                    const join_sale_returns = res_join_returns?.data?.joined

                    this.isLoading = false  
                    let return_data = this.return_data?this.return_data:{} 
                    return_data = {
                        ...return_data,
                        receipt:receipt,
                        searching_key:this.search,
                        searched_receipts:this.searched_receipts,
                        sale_order:sale_order,
                        join_sale_order:join_sale_order,
                        sales_returns:sales_returns,
                        join_sale_returns:join_sale_returns,
                    }
                    this.return_data = return_data
                   
                    this.$emit("input",return_data)  
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'SEARCH_SALES_ORDER',
                        page:PAGE_NAME, 
                    }) 
                }
                
            }, 
            //---------------[MAIN FUNCTIONS]------------ 
            DIALOG_YES(action){ 
                try { 
                    if (action.code) {
                        if (action.code ===PAGE_NAME+'=ADD-ITEM') {
                            if (action.YES) {
                                if (this.input.check) { 
                                    this.input.check = false 
                                    setTimeout(() => {
                                        this.input.loading = true 
                                        this.$store.dispatch("fi_add",{
                                            action:this.VALUE_ITEM,
                                            path:this.PATH_ITEM,
                                            data:action.data,
                                            us:this.us
                                        })
                                    }, ACTION_TIME);  
                                }   
                            } else {
                                
                            }
                        }  
                    } 
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'DIALOG_YES',
                        page:PAGE_NAME, 
                    }) 
                }  
            },  
            PAGE_PERMISSION(){
                try {
                    let us
                    if (!us) {
                        this.MBS.actions.go("/")
                    }  
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'PAGE_PERMISSION',
                        page:PAGE_NAME, 
                    })
                }
            },
        },
        beforeDestroy(){
            try {
                
            } catch (error) {
                this.MBS.actions.error({
                    error:error,
                    from:'beforeDestroy',
                    page:PAGE_NAME, 
                })
            }
        },
        watch:{ 
            us(v){
                this.PAGE_PERMISSION()
            }, 
            show(value){
                if (value) {
                    this.show_dialog = true
                }else{
                    this.show_dialog = false
                }
            },
            fixed(value){
                if (value) {
                    this.fixed_dialog = true
                }else{
                    this.fixed_dialog = false
                }
            },
            async search (val) { 
                this.SEARCH_RECEIPTS(val)  
            },
            input_receipt (receipt) { 
                let return_data = this.return_data?this.return_data:{} 
                return_data = {
                    ...return_data,
                    receipt:receipt,
                    searching_key:this.search,
                    searched_receipts:this.searched_receipts
                }
                this.return_data = return_data
                this.$emit("input",return_data)  
                if (receipt) {
                    this.show_dialog = false
                    this.$emit("find_selected",return_data)  
                }
            }, 
            show_dialog (value) { 
                setTimeout(() => {
                    let return_data = this.return_data?this.return_data:{} 
                    return_data = {
                        ...return_data,
                        closed:!value
                    }
                    this.return_data = return_data
    
                    this.$emit("input",return_data)   
                    if (value==true) {
                        this.$emit("find_opened",return_data)  
                    }else if(value == false){
                        this.$emit("find_closed",return_data)  
                    } 
                }, 1000);
            },
        } 

    }
</script>
 